import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import BackgroundImage from 'gatsby-background-image'
import ReactModal from 'react-modal'
// Components
import { BackgroundColor } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import TextSlider from 'components/TextSlider'
// Styling
import 'styles/ThePowerTemplate.scss'
// Images
// import playButtonIcon from 'img/play-icon.svg'
import SplitBanner from '../components/SplitBanner'

const customStyles = {
  content: {
    width: '40%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    minWidth: '400px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 30px 60px rgba(0,0,0,0.12)',
    border: 'none',
    borderRadius: '5px',
    paddingBottom: '15px',
  },
  overlay: {
    zIndex: 2147483647,
    backgroundColor: "rgba(0, 0, 0, 0.7)"
  },
}


const ThePower = ({ data, pageContext }) => {
  const [isModalOpen, setModalStatus] = useState(false)

  const {
    wordpressPage: {
      title,
      acf: {
        hero,
        detail,
        checkmarks,
        split_banner: splitBanner,
        // comes later in the year
        // before_footer
      },
    },
  } = data

  return (
    <div className="the-power-template">
      <div className="the-power-template-gradient" />
      <Layout>
        <SEO title={title} />
        <BackgroundImage
          tag="div"
          className="the-power-template-banner"
          fluid={hero.image.localFile.childImageSharp.fluid}
        >
          <div className="container h-100 d-flex align-items-end">
            <div className="row w-100 pb-5">
              <div className="card card--not-clickable col-lg-5 mb-5 py-3 px-4">
                <div className="d-block w-100">
                  <Content
                    className="nested color-text-main font-weight-roman font-family-main"
                    content={hero.description}
                  />

                  <Content content={hero.title} className="mt-4 font-size-xl font-weight-xl nested" />
                  {/* <button type="button" className="button mt-3 button-alt px-3" onClick={() => setModalStatus(true)}>
                    <img className="mr-3" src={playButtonIcon} alt="" />
                    {hero.video_button_text}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>

        <section id="more" className="container my-3 mb-sm-3 mb-lg-5">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbSeparator={' > '} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={title} />
            </div>
          </div>
        </section>

        <section className="container my-3 mb-sm-3 mb-lg-5">
          <div className="row">
            <div className="col-lg-6">
              <Content content={detail.title} className="nested font-size-xxl font-weight-xl" />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-8 mb-4">
              <Content
                className="color-text-secondary"
                content={detail.under_title}
              />
            </div>

            <div className="col-lg-6 pl-lg-5">
              <div className="pl-lg-5 pr-lg-5">
                <Content content={detail.left_column} />
              </div>
            </div>

            <div className="col-lg-6 pl-lg-5 d-flex justify-content-center">
              <div className="pl-lg-5 pr-lg-5">
                <img src={detail.right_column.localFile.publicURL} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="mt-3">
          <BackgroundColor className="pt-5 pb-3 pb-lg-5 color-background-main">
            <div className="col-12">
              <Content
                content={checkmarks.title}
                className="nested text-slider-title color-text-light text-center font-size-xl font-weight-xl px-lg-5 mx-lg-5"
              />
            </div>
            <div className="w-100 empty-space-50" />
            <div className="row justify-content-center w-100">
              <TextSlider content={checkmarks.list} />
            </div>
          </BackgroundColor>
        </section>

        <SplitBanner
          image={splitBanner.image}
          quote={{
            name: splitBanner.author.name,
            function: splitBanner.author.function,
          }}
          content={splitBanner.quote}
        />

        <ReactModal
          ariaHideApp={false}
          style={customStyles}
          isOpen={isModalOpen}
          onRequestClose={() => setModalStatus(false)}
        >
          <iframe title="video" src={hero.video_link} className="modal-video" />
        </ReactModal>

        {/* <section className="before_footer container mt-3 pt-5"> */}
        {/*  <div className="row"> */}
        {/*    <div className="col-lg-6 pr-lg-5"> */}
        {/*      <div className="pr-lg-5"> */}
        {/*        <Content content={before_footer.left.title} /> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*    <div className="col-lg-6"> */}
        {/*      <Content */}
        {/*        className="color-text-secondary text-justify" */}
        {/*        content={before_footer.right.title} */}
        {/*      /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/*  <div className="the-power-template-community row pt-3"> */}
        {/*    <Community */}
        {/*      image={before_footer.left.bottom_column.image.localFile.childImageSharp.fluid} */}
        {/*      content={before_footer.left.bottom_column.title} */}
        {/*      link={before_footer.left.bottom_column.link} */}
        {/*    /> */}
        {/*    <Community */}
        {/*      image={before_footer.right.bottom_column.image.localFile.childImageSharp.fluid} */}
        {/*      content={before_footer.right.bottom_column.title} */}
        {/*      link={before_footer.right.bottom_column.link} */}
        {/*    /> */}
        {/*  </div> */}
        {/* </section> */}
      </Layout>
    </div>
  )
}

// const ThePowerPoint = ({ title, text }) => (
//   <div className="mb-5 pl-lg-5">
//     <h3 className="font-weight-xl font-size-m color-text-main">{title}</h3>
//     <Content className="color-text-secondary mt-2" content={text} />
//   </div>
// )

// const Community = ({ image, content, link = "#" }) => (
//   <BackgroundImage
//     className="the-power-template-community-item col-lg-6"
//     fluid={image}
//   >
//     <div className="the-power-template-community-item-overlay" />
//     <Link to={link} className="the-power-template-community-item-content h-100 d-flex justify-content-start align-items-end">
//       <div className="px-4 py-5">
//         <Content content={content} />
//       </div>
//     </Link>
//   </BackgroundImage>
// )

export const pageQuery = graphql`
	query ThePowerQuery($wordpress_id: Int!) {
		wordpressPage(wordpress_id: { eq: $wordpress_id }) {
			id
			wordpress_id
			title
			slug
			acf {
				hero {
					description
					title
					video_link
					video_button_text
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}

				detail {
					title
					under_title
					left_column
					right_column {		
            localFile {
              publicURL
            }
					}
				}

				checkmarks {
					title
					list {
						content
					}
				}

				split_banner {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1002) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					quote
					author {
						function
						name
					}
				}

				# before_footer {
				#   left {
				#     title
				#     bottom_column {
				#       link
				#       title
				#       image {
				#         localFile {
				#           childImageSharp {
				#             fluid(quality: 100, maxWidth: 600) {
				#               ...GatsbyImageSharpFluid_withWebp
				#             }
				#           }
				#         }
				#       }
				#     }
				#   }
				#  
				#   right {
				#     title
				#     bottom_column {
				#       link
				#       title
				#       image {
				#         localFile {
				#           childImageSharp {
				#             fluid(quality: 100, maxWidth: 600) {
				#               ...GatsbyImageSharpFluid_withWebp
				#             }
				#           }
				#         }
				#       }
				#     }
				#   }
				# }
			}
		}
	}
`

export default ThePower
